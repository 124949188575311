// change this file to typescript
const dashboard = ({ className, fillColor }: { className: string, fillColor: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 13.713C2 8.082 2.64052 8.475 6.08825 5.41C7.5967 4.246 9.94388 2 11.9708 2C13.9967 2 16.3908 4.235 17.9128 5.41C21.3605 8.475 22 8.082 22 13.713C22 22 19.9564 22 12 22C4.04361 22 2 22 2 13.713Z"
        fill={fillColor}
      />
      <path
        d="M9.75 16.25H14.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default dashboard;
