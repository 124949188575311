import React from 'react'
import logo from '../assets/Logo.svg'

const splash = () => {
  return (
    <div className='fixed top-0 left-0 w-full h-full bg-atlas_black flex justify-center items-center '>
      <img className="animate-pulse" src={logo} alt='logo'/>
    </div>
  )
}

export default splash