// TableComponent.jsx
import React from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

interface Column {
  field: string;
  headerName: string;
  renderCell?: (row: any) => JSX.Element;
}

interface crypto {
  imageSrc: any;
  title: string;
  description: string;
}

interface amount {
  amount: number;
  currency: string;
}

interface change {
  percentage: number;
  status: string;
}

interface DataRow {
  id: number;
  crypto: crypto[]; // Now supports a variable number of items
  amount: amount[];
  price: number;
  change: change[];
  action: () => void;
}

interface ResponsiveTableProps {
  columns: Column[];
  data?: DataRow[]; 
}

const TableComponent: React.FC<ResponsiveTableProps> = ({ columns, data }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full font-sans table-auto border border-solid border-white/10 rounded-xl border-separate">
        <thead className="border-b">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="text-sm font-medium text-white/50 px-6 py-4 text-left"
              >
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex} className="">
              {row.crypto.map((item, itemIndex) => (
                <td
                  key={itemIndex}
                  className="text-sm font-light px-6 py-4 whitespace-nowrap"
                >
                  <div className="flex">
                    <img
                      src={item.imageSrc}
                      alt=""
                      className="w-10 h-10 rounded-full"
                    />
                    <div className="flex flex-col pl-3 text-white font-semibold text-sm ">
                      {item.title}
                      <div className="text-white font-normal text-xs">{item.description}</div>
                    </div>
                  </div>
                </td>
              ))}
              {row.amount.map((item, itemIndex) => (
                <td
                  key={itemIndex}
                  className="text-sm font-light px-6 py-4 whitespace-nowrap"
                >
                  <div className="flex">
                    <div className="flex flex-col text-white font-semibold text-sm ">
                      {item.amount}
                      <div className="text-white font-normal text-xs">{item.currency}</div>
                    </div>
                  </div>
                </td>
              ))}
                <td
                  className="text-sm font-light text-white px-6 py-4 whitespace-nowrap"
                >
                  ${row.price}
                </td>
              {row.change.map((item, itemIndex) => (
                <td
                  key={itemIndex}
                  className="text-sm font-light px-6 py-4 whitespace-nowrap"
                >
                    <div className={`w-3/4 py-1 flex justify-center rounded-md item-center text-white font-semibold text-sm ${item.status === 'increase' ? 'bg-atlas_green/20' : 'bg-red-500/20'}`}>
                        <div className="flex items-center">
                        {item.status === 'increase' ? <MdArrowUpward className="text-atlas_green"/> : <MdArrowDownward className="text-red-500"/>}
                        </div>
                        <p className={`${ item.status === 'increase' ? 'text-atlas_green' : 'text-red-500'} font-normal text-xs`}>{item.percentage}%</p>
                  </div>
                </td>
              ))}
              <td className=" px-6 py-4">
                <button className="text-white text-xs " onClick={row.action}>
                  Manage
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
