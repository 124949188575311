import { useState } from "react";
import { toast } from "react-toastify";
import emoji from "../../assets/icon _Face cool_.svg";
import { useAppContext } from "../../Context/AppContext";
import OtpComponent from "../OtpComponent";
import Spinner from "../spinner";

interface EmailIdProps {
  onComplete: () => void;
}

const EmailId: React.FC<EmailIdProps> = ({ onComplete }) => {
  const [user, setUser] = useState<any>(JSON.parse(localStorage.getItem("user") || "{}"));
  const { sendRequest } = useAppContext();
  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  function handleSubmit() {
    setError("");
    if (otp.length < 6) {
      setError("Please enter the complete code");
      return;
    }
    setLoading(true);
    sendRequest("POST", "/api/v1/verify-email", { email: user.email, code: otp })
      .then(
        (response: {
          data: {
            message: string;
            data: {
              access_token: string;
              refresh_token: string;
              user: any
            }
          };
        }) => {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          setUser(response.data.data);
          toast.success("Email verified successfully");
          onComplete && onComplete()
        }).catch((err: any) => {
          let errMsg = "An error occurred, please try again";
          if (err.response && err.response.data && err.response.data.errors) {
            errMsg = err.response.data.errors;
          }
          setError(errMsg)
        })
      .finally(() => { setLoading(false) });
  }

  function resendCode() {
    sendRequest("POST", "/api/v1/resend-code", { email: user.email }).
      then((response: any) => {
        toast.success("Code sent successfully");
      }).catch((err: any) => {
        let errMsg = "An error occurred, please try again";
        if (err.response && err.response.data && err.response.data.errors) {
          errMsg = `${err.response.data.errors}`;
        }
        setError(errMsg)
      })
  }

  return (
    <div className="bg-white dark:bg-atlas_black">
      <div className="lg:w-5/6 w-full pt-20 md:pt-0 lg:pl-28 px-10">
        <h3 className="text-4xl font-sans text-atlas_black dark:text-white pt-8 pb-2">
          OTP
        </h3>
        <p className="font-sans italic text-atlas_black dark:text-white text-opacity-70 text-sm pb-10">
          Enter the 6 digit Verification code send to your mobile number
        </p>

        <div className="font-sans pb-24">
          <OtpComponent onComplete={setOtp} />
          <div className="flex justify-between items-center text-sm pt-5 text-atlas_orange">
            <p className="font-sans text-opacity-70">
              {/* Enter the 6 digit code sent to {user.email} */}
              Your code expires in 58 seconds
            </p>
            <div className="">
              <p className="cursor-pointer text-nowrap underline hover:brightness-75" onClick={resendCode}>Resend Code</p>
            </div>
          </div>
        </div>

        {error ? <div className="py-2 text-sm font-semibold text-red-500">
          {error}
        </div> : ""}
        <div className="flex">
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="py-2 w-full rounded-md text-black font-semibold mt-4 bg-atlas_orange">
            {loading ? <Spinner /> : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailId;
