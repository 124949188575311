interface CardProps {
    imageSrc?: string;
    title: string;
    description: string;
  }
  
  function CarouselCard({ imageSrc, title, description }: CardProps) {
    return (
      <div className="carousel-card rounded-xl overflow-hidden bg-atlas_silver flex flex-col h-full lg:ml-16">
        {/* Add 'mx-5' to apply 20px margin to left and right of each card */}
        <div className="flex-1 px-6 pt-6">
          <h2 className="font-bold text-xl pb-4">{title}</h2>
          <p className="text-gray-700 text-base">{description}</p>
        </div>
        {imageSrc && (
          <img
            className="w-full h-56 object-contain transition-all duration-300 ease-in-out -mt-6"
            src={imageSrc}
            alt={title}
          />
        )}
      </div>
    );
  }
  
  export default CarouselCard;
  