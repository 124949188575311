import React from "react";
import BoxIcon from "../../assets/boxicon.svg";
import Share from "../../assets/share.svg";
import SendIcon from "../../assets/sendIcon.svg";
import { Toaster, toast } from "alert";

const AccountsPage = () => {
  const data = [
    { label: "UserName", value: "JohnPrime415" },
    { label: "Date of Birth", value: "1992 - 05 - 01" },
    { label: "Address", value: "Lagos, Nigeria" },
    { label: "Email", value: "johndoe@email.com" },
    { label: "Identity Document", value: "23*******02" },
  ];

  const handleCopy = async () => {
    const copyText = document.getElementById(
      "invite-email"
    ) as HTMLInputElement;
    await navigator.clipboard.writeText(copyText.value);
    toast.success("Copied to clipboard ");
  };

  const SendInvitation = () => {
    toast.success("Invitation sent successfully");
  };

  return (
    <div>
      <Toaster position="top-right" />

      <div className="flex lg:flex-row flex-col gap-5">
        <div className="flex-1 mx-8 font-sans">
          <p className="text-white py-4 font-extralight text-sm">
            Account {">"}{" "}
          </p>

          <div className="flex w-full gap-7 item-center mt-5">
            <div className="border border-solid border-atlas_gold rounded-lg bg-atlas_gray px-3 py-3">
              <img src={BoxIcon} alt="" className="w-12 h-12" />
            </div>
            <div className="text-white">
              <p className="mt-2">
                {" "}
                <span className="text-white/70">User ID :</span> 12345
              </p>
              <p className="my-1">
                {" "}
                <span className="text-white/70">Verification level :</span>{" "}
                Verified
              </p>
            </div>
          </div>

          <div className="mt-24 ">
            <h4 className="text-white font-medium">Personal Information</h4>

            <div className="w-full">
              {data.map((item, index) => (
                <div className="flex justify-between lg:my-3 my-8" key={index}>
                  <p className="text-white/70">{item.label}</p>
                  <p className="text-white">{item.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex-1 justify-center items-center mb-4 lg:mt-12 lg:mx-16 px-8 py-10 bg-atlas_gray font-sans rounded-xl">
          <h2 className="text-atlas_gold">Share the referral link</h2>
          <p className="text-gray-400">
            Copy and share the link below to refer to refer
          </p>
          <div className="mt-5">
            <div className="relative w-full">
              <input
                type="email"
                id="invite-email"
                value="https://atlaxchange.com/join/JohnPrime415"
                disabled
                className="w-full h-12 pl-4 pr-16 py-4 bg-white rounded-lg font-sans text-blue-400"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                <button
                  onClick={handleCopy}
                  className="py-2 px-3 bg-atlas_gold text-white rounded-lg text-base"
                >
                  <img src={Share} alt="send" className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>

          <div className="relative flex items-center my-8 px-28">
            <div className="flex-grow border-t border-gray-500"></div>
            <span className="flex-shrink mx-4 text-gray-500 font-sans">or</span>
            <div className="flex-grow border-t border-gray-500"></div>
          </div>

          <h2 className="text-atlas_gold">Invite your friend 🤝</h2>
          <p className="text-gray-400">
            Input your friends email address to send them an invitation.
          </p>

          <div className="my-5">
            <div className="relative w-full">
              <input
                type="email"
                placeholder="Input Email address"
                required
                className="w-full h-12 pl-4 pr-16 bg-white rounded-lg font-sans"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                <button
                  className="py-2 px-3 bg-atlas_gold text-white rounded-lg text-base"
                  onClick={SendInvitation}
                >
                  <img src={SendIcon} alt="send" className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>

          <p className="text-sm text-white/50 py-5">
            Spread the word about our amazing services to your friends and reap
            the benefits! For every friend you refer who joins Atlaxchange,
            you'll receive a sum of N1000 as a token.
          </p>

          <ul className="text-sm list-decimal pl-5 pt-3 text-white/50">
            <li>Share your unique referral link with your friends.</li>
            <li>Your friend signs up using your link.</li>
            <li>
              Once they join, you both earn – they get access to our fantastic
              services, and you get N1000
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AccountsPage;
