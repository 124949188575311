import React, { useState } from "react";
import emoji from "../../assets/icon _emoji smile upside down_.svg";

interface Props {
  onComplete?: () => void;
}

const PersonalInfo = ({ onComplete }: Props) => {
  const [postcode, setPostCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("+234"); // Default to +234 (Nigeria)
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const userData = JSON.parse(localStorage.getItem("user") || "{}");

  const PhoneNumberChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value;
    const isNumeric = /^-?\d*$/.test(inputValue);

    if (isNumeric && inputValue.length <= 11) {
      setPhoneNumber(inputValue);
    }
  };

  return (
    <div>
      <div className="flex justify-end pt-6 pr-9">
        <p className="flex items-center font-sans text-white text-sm">
          We really need this part &nbsp; <img src={emoji} alt="emoji" />{" "}
        </p>
      </div>
      <div className="lg:w-4/6 w-full pt-14 lg:pl-20 px-10">
        <h3 className="text-4xl font-sans text-white font-semibold pt-8 pb-2">
          Personal Information
        </h3>
        <form className="pt-10 font-sans">
          <div className="grid lg:grid-cols-2 lg:gap-4">
            <div>
              <label
                htmlFor="email"
                className="text-white font-normal text-sm mb-2 block"
              >
                Legal First name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="email"
                className="px-2 py-2 mb-10 w-full text-white/50 bg-atlas_black border-opacity-20 border focus:ring-0 border-atlas_grey rounded-md focus:outline-none focus:border-atlas_gold"
                value={userData.first_name}
                disabled
              />
            </div>

            <div>
              <label
                htmlFor="lastname"
                className="text-white font-normal text-sm mb-2 block"
              >
                Legal Last name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="lastname"
                className="px-2 py-2 mb-10 w-full text-white/50 bg-atlas_black border-opacity-20 border focus:ring-0 border-atlas_grey rounded-md focus:outline-none focus:border-atlas_gold"
                value={userData.last_name}
                disabled
                required
                placeholder="Last name"
              />
            </div>
          </div>

          <div className="flex gap-2">
            <select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="pl-2 py-2 block mb-10 w-20 text-white bg-atlas_black border-opacity-20 border focus:ring-0 border-atlas_grey rounded-md focus:outline-none focus:border-atlas_gold outline outline-neutral-700 border-transparent"
            >
              <option value="+234">+234</option>
              {/* <option value="+44">+44</option> */}
            </select>

            <input
              type="text"
              value={phoneNumber}
              onChange={PhoneNumberChange}
              className="px-2 py-2 mb-10 w-2/4 text-white bg-atlas_black border-opacity-20 border focus:ring-0 border-atlas_grey rounded-md focus:outline-none focus:border-atlas_gold"
              placeholder="Phone Number"
              required
              inputMode="numeric"
            />
            {/* Add a button or form submit trigger, calling handleSubmit */}
          </div>
          <label
            htmlFor="email"
            className="text-white font-normal text-sm mb-2 block"
          >
            Residential Address <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="email"
            className="px-2 py-2 mb-10 w-full text-white bg-atlas_black border-opacity-20 border focus:ring-0 border-atlas_grey rounded-md focus:outline-none focus:border-atlas_gold"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            placeholder="Residential Address"
          />

          <div className="grid lg:grid-cols-2 lg:gap-4">
            <div>
              <label
                htmlFor="post"
                className="text-white font-normal text-sm mb-2 block"
              >
                Postal Code <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="postcode"
                className="px-2 py-2 mb-10 w-full text-white bg-atlas_black border-opacity-20 border focus:ring-0 border-atlas_grey rounded-md focus:outline-none focus:border-atlas_gold"
                value={postcode}
                onChange={(e) => setPostCode(e.target.value)}
                required
                placeholder="Postal Code"
              />
            </div>

            <div>
              <label
                htmlFor="city"
                className="text-white font-normal text-sm mb-2 block"
              >
                City <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="city"
                className="px-2 py-2 mb-10 w-full text-white bg-atlas_black border-opacity-20 border focus:ring-0 border-atlas_grey rounded-md focus:outline-none focus:border-atlas_gold"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
                placeholder="City"
              />
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={() => onComplete && onComplete()}
              className="py-2 px-7 rounded-md text-black font-semibold mt-4 bg-atlas_gold"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInfo;
