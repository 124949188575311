import React from "react";
import successface from "../../assets/successface.svg";

interface SuccessModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose} // Close modal on overlay click
      ></div>

      {/* Modal Content */}
      <div className="relative bg-white p-12  rounded-lg shadow-lg max-w-sm w-full z-10 flex flex-col justify-center items-center">
        <h2 className="text-lg font-normal text-center pb-10">
          You have successfully joined the waitList
        </h2>
        
        <img src={successface} alt="success" className="pb-10" />
           <button
          className=" w-full bg-atlas_orange text-white py-2 px-4 rounded-lg mt-4"
          onClick={onClose}
        >
          Close
        </button> 
        </div>
        
      </div>
  );
};

export default SuccessModal;
