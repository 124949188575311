import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import Logo from "../../assets/homepageLogo.svg"
import { HashLink } from 'react-router-hash-link'


import "react-toastify/dist/ReactToastify.css";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const notify = () => {
    toast.info("Coming Soon!", {
      position: "top-center",
      theme: "dark",
      progressClassName: "!bg-atlas_gold",
    });
  };

  return (
    <div>
      <div className="p-[2px] mx-4 sm:mx-8 md:mx-16 lg:mx-16 overflow-hidden">
        <span className="flex justify-between items-center w-full text-black py-3 px-5">
          <HashLink to="/">
            <img className="lg:flex md:flex hidden" src={Logo} alt="logo"  />
          </HashLink>
          <div className="lg:hidden md:hidden flex justify-between w-full">
          <HashLink to="/">
            <img src={Logo} alt="logo"  />
          </HashLink>
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="md:hidden"
            >
              {isDropdownOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
            </button>
          </div>

          <div>
            <ul className="lg:flex md:flex hidden justify-between gap-14 text-black font-manrope font-semibold text-base cursor-pointer">
              <li>
                <HashLink
                  smooth to="/#services"
                  className="hover:text-atlas_orange"
                >
                  Our Services
                </HashLink>
              </li>
              <li className="hover:text-atlas_orange">
                <HashLink smooth to="/#about-us">About Us</HashLink>
              </li>
              <li className="hover:text-atlas_orange">
                <HashLink smooth to="/#contact-us">Contact Us</HashLink>
              </li>
              <li className="hover:text-atlas_orange">
                <HashLink smooth to="/#faq">FAQ</HashLink>
              </li>
            </ul>
          </div>

          <div className="lg:flex md:flex hidden justify-between gap-4 text-sm">
            {/* <HashLink to="/login">
              <button className="py-1 px-3 border border-solid border-atlas_gold rounded-full text-atlas_gold">
                Log In
              </button>
            </HashLink> */}
            <HashLink to="/waitinglist">
              <button className="py-2 px-3 bg-atlas_orange text-white rounded-lg font-manrope font-semibold">
               Join waitlist
              </button>
            </HashLink>
          </div>
        </span>
      </div>
      <div
        className={`absolute top-20 right-0 left-0 mt-2 mx-4 sm:mx-8 md:mx-16 bg-atlas_black text-white rounded-md shadow-lg ${
          isDropdownOpen ? "block" : "hidden"
        } md:hidden`}
        style={{ zIndex: 1000 }}
      >
        <ul className="py-1">
          <li>
            <HashLink
              smooth to ="/#services"
              className="block px-4 py-2 hover:bg-gray-100"
            >
             Our Services
            </HashLink>
          </li>
          <li onClick={notify} className="block px-4 py-2 hover:bg-gray-100">
            <HashLink to="/about-us">About Us</HashLink>
            
          </li>
          <li className="block px-4 py-2 hover:bg-gray-100">
            <HashLink to="/contact-us">contact-Us</HashLink>
          </li>
          <li className="block px-4 py-2 hover:bg-gray-100">
            <HashLink to="/faq">FAQ</HashLink>
          </li>
        </ul>

        <div className=" flex gap-5 px-4 py-2">
          {/* <HashLink to="/login">
            <button className="py-1 px-3 border border-solid border-atlas_gold rounded-full text-atlas_gold">
              Log In
            </button>
          </HashLink> */}
          <HashLink to="/signup">
            <button className="py-2 px-3 bg-atlas_orange text-white rounded-lg font-manrope font-semibold">
              Join waitlist
            </button>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
