import React, { useState, useEffect } from "react";
import CalenderIcon from "./CalenderIcon";
import Modal from "../Modals/Modal";
import Ellipse from "../../assets/Ellipse.svg";
import { Link } from "react-router-dom";
import LoansAssets from "./LoansAssets";
import RightSidebar from "./RightSidebar";

const Loans = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [currency, setCurrency] = useState("NGN");
  const [totalLoans, setTotalLoans] = useState("1,000,000");

  function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleCurrencyChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCurrency(event.target.value);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleTitle = (title: string) => {
    setContentType(title);
  };

  useEffect(() => {
    switch (currency) {
      case "NGN":
        setTotalLoans("1,000,000");
        break;
      case "USD":
        setTotalLoans((1000000 / 1500).toLocaleString());
        break;
      case "EUR":
        setTotalLoans((1000000 / 1700).toLocaleString());
        break;
      case "GBP":
        setTotalLoans((1000000 / 2000).toLocaleString());
        break;
      default:
        setTotalLoans("1,000,000");
        break;
    }
  }, [currency]);

  return (
    <div className="flex">
    <div className=" mx-6 my-4 font-sans lg:w-4/5 md:w-4/5 w-full">
      <p className="text-white pb-4 font-sans font-extralight text-sm">
        Loan {">"}{" "}
      </p>

      <div className="lg:flex md:flex block justify-between">
        <div className="lg:w-7/12 md:w-7/12 w-full flex-auto border-atlas_gold border border-solid px-3 md:px-6 lg:px-6 py-4 mb-4 lg:mb-0 md:mb-0 bg-atlas_gray rounded-lg text-white">
          <div className="flex items-center">
            <p className="font-medium text-sm">Total Loans</p>
            <select
              onChange={handleCurrencyChange}
              className="bg-atlas_gray border-none focus:outline-none text-xs font-light ml-2"
            >
              <option value="NGN">NGN</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
            </select>
          </div>

          <p className="font-medium text-4xl">{totalLoans} </p>

          <p className="pt-3 text-sm">
            Available Loan :{" "}
            <span className="text-blue-600 underline">1,000,000</span>{" "}
          </p>
        </div>

        <div className="lg:flex-auto md:flex-auto hidden lg:flex md:flex relative">
          <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 bg-black h-px"></div>
        </div>

        <div className="lg:w-4/12 md:w-4/12 w-full flex-auto  px-3 md:px-6 lg:px-6 py-4 bg-atlas_gray rounded-lg text-white">
          <div>
            <p className="font-medium text-sm">All Loans</p>

            <p className="font-medium text-4xl">3 </p>

            <div className="flex justify-between pt-3 text-sm">
              <p>Loans</p>
              <Link to="/dashboard/loans">
                <p className="text-blue-600 underline">View Loans</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4">
        <button
          onClick={() => {
            handleTitle("NewLoan");
            handleOpenModal();
          }}
          className="text-white py-2 px-3 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_gold border-white/10 rounded-lg"
        >
          Create New Loan
        </button>
        <button
          onClick={() => {
            handleTitle("Repay");
            handleOpenModal();
          }}
          className="text-white py-2 px-3 bg-atlas_gray border-2 border-solid text-sm mx-2 md:mx-4 lg:mx-4 hover:bg-atlas_gold border-white/10 rounded-lg"
        >
          Repay Loan
        </button>
      </div>

      <div className="my-24">
        <div className="flex justify-between text-white text-sm mb-5">
          <p>Loans Assets</p>
          <Link to="/dashboard/loans">
            <p className="text-blue-600 underline">View Eligible Assets</p>
          </Link>
        </div>

        <div className="grid gap-6 lg:grid-cols-3 md:grid-cols-3 grid-cols-1 ">
          {LoansAssets.map((asset, index) => (
            <div className="bg-atlas_gray px-5 py-3 rounded-lg" key={index}>
              <div className="flex justify-between items-center pb-4">
                <div className="flex gap-2 items-center ">
                  <img src={Ellipse} alt="" className="w-5 h-5 rounded-full" />
                  <p className="text-white text-xs">Loan {asset.index}</p>
                </div>

                <div className="">
                  <CalenderIcon number={asset.date} />
                </div>
              </div>
              <div>
                <p className="text-white font-semibold text-base">
                  {asset.cryptoAmount}{" "}
                  <span className="font-light text-sm">{asset.crypto}</span>
                </p>
                <div className="text-white/50 font-normal text-xs">
                  {numberWithCommas(asset.amount)} NGN
                </div>
              </div>
              <div className="mt-6 flex items-start">
                <button
                  onClick={() => {
                    handleTitle("Repay");
                    handleOpenModal();
                  }}
                  className="text-white py-2 px-3 bg-atlas_gray border-2 border-solid text-xs  hover:bg-atlas_gold border-white/10 rounded-lg"
                >
                  Repay Loan
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        type={contentType}
      />
    </div>
    
    <div className="hidden lg:flex border-l border-atlas_gray flex-grow">
        <RightSidebar />
      </div>
    </div>
  );
};

export default Loans;
