import { Routes, Route } from "react-router-dom";
import Header from "../../Components/Dashboard/Header";
import Sidebar from "../../Components/Dashboard/Sidebar";
import DashboardPage from "../../Components/Dashboard/DashboardPage";
import WalletPage from "../../Components/Dashboard/WalletPage";
import LoansPage from "../../Components/Dashboard/LoansPage";
import TradePage from "../../Components/Dashboard/TradePage";
import AccountsPage from "../../Components/Dashboard/AccountsPage";
import SettingsPage from "../../Components/Dashboard/SettingsPage";


const Dashboard = () => {
  // Function to render the active component based on the active item name
  return (
    <div className="bg-atlas_dark w-full h-full min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-grow overflow-hidden">
        <Sidebar />
        <div className="lg:ml-60 mx-6 flex-grow">
          {/* Offsets the main content */}
            {
              <Routes>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/wallet" element={<WalletPage />} />
                <Route path="/loans" element={<LoansPage />} />
                <Route path="/trade" element={<TradePage />} />
                <Route path="/accounts" element={<AccountsPage />} />
                <Route path="/settings" element={<SettingsPage />} />
              </Routes>
            }
        </div>
        
      </div>
    </div>
  );
};

export default Dashboard;
