import React, { useState } from "react";
import Ellipse from "../../assets/Ellipse.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Modal from "../Modals/Modal";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import AssetsData from "./assetdata";
import RightSidebar from "./RightSidebar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const WalletPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [selected, setSelected] = useState(0);

  const handleItemClick = (id:any) => {
    setSelected(id); // Set the clicked item as active
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleTitle = (title: string) => {
    setContentType(title);
  };

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

  const options = {
    scales: {
      y: {
        ticks: {
          display: false, // Hides the tick labels on the y-axis
        },
        grid: {
          display: false, // Hides the grid lines for the y-axis
        },
      },
      // Optionally, do the same for the x-axis if needed
      x: {
        ticks: {
          font: {
            family: "work sans",
            size: 14, // Specify the font family for x-axis tick labels
          },
          display: true,
          color: "rgba(255,255,255 , 0.7)", // Hides the tick labels on the x-axis
        },
        grid: {
          display: false, // Hides the grid lines for the x-axis
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: [20, 70, 40, 60, 90, 50],
        fill: true,
        borderColor: "#D5B071",
        // Use a function for the backgroundColor to apply the gradient
        backgroundColor: function (context: { chart: any }) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(0, "rgba(46,46,46, 0.7)"); // black
          gradient.addColorStop(1, "rgba(213,176,113, 0.9)"); // red

          return gradient;
        },
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="flex">
    <div className="mx-6 my-4 font-sans lg:w-4/5 w-full">
      <p className="text-white pb-4 font-sans font-extralight text-sm">
        Wallet {">"}{" "}
      </p>
      <div className="lg:flex md:flex block gap-4">
        <div className="lg:w-7/12 md:w-7/12 w-full flex-auto border-atlas_gold border border-solid px-3 md:px-6 lg:px-6 py-4 bg-atlas_gray rounded-lg text-white">
          <div className="flex items-center gap-2">
            <img src={Ellipse} alt="" className="w-5 h-5" />
            <p className="font-semibold text-lg">NGN</p>
          </div>

          <p className="font-medium text-4xl">
            1,000,000.00{" "}
            <span className="font-extralight text-sm">
              <sub>NGN</sub>
            </span>
          </p>

          <div className="my-4">
            <button
              onClick={() => {
                handleTitle("Trade");
                handleOpenModal();
              }}
              className="text-white py-2 px-3 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_gold border-white/10 rounded-lg"
            >
              Trade
            </button>
            <button
              onClick={() => {
                handleTitle("Buy");
                handleOpenModal();
              }}
              className="text-white py-2 px-3 bg-atlas_gray border-2 border-solid text-sm mx-2 md:mx-4 lg:mx-4 hover:bg-atlas_gold border-white/10 rounded-lg"
            >
              Withdraw
            </button>
            <button
              onClick={() => {
                handleTitle("Convert");
                handleOpenModal();
              }}
              className="text-white py-2 px-3 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_gold border-white/10 rounded-lg"
            >
              Convert
            </button>
          </div>
        </div>

        <div className="lgw-5/12 md:w-5/12 w-full flex-auto pt-4 ">
          <div className="w-full">
            <Line options={options} data={data} />
          </div>
        </div>
      </div>

      <div className="lg:flex md:flex block gap-4 my-14 text-white font-sans">
        <div className="lg:w-7/12 w-full flex-auto ">
          <p>My Assets</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-4 cursor-pointer">
            {AssetsData.map((item , index) => (
              <div key={index} className={`w-full bg-atlas_gray rounded-lg my-4 px-5 py-5 ${selected === index ? 'border border-solid border-atlas_gold' : ' '}`} onClick={() => handleItemClick(index)}>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <img src={Ellipse} alt="ellipse" className="w-5 h-5" />
                    <p className="text-xs">{item.description}</p>
                  </div>
                  <div
                    className={`py-1 px-1 flex justify-center rounded-md item-center text-white font-semibold text-sm ${
                      item.status === "increase"
                        ? "bg-atlas_green/20"
                        : "bg-red-500/20"
                    }`}
                  >
                    <div className="flex items-center">
                      {item.status === "increase" ? (
                        <MdArrowUpward className="text-atlas_green" />
                      ) : (
                        <MdArrowDownward className="text-red-500" />
                      )}
                    </div>
                    <p
                      className={`${
                        item.status === "increase"
                          ? "text-atlas_green"
                          : "text-red-500"
                      } font-normal text-xs`}
                    >
                      {item.percentage}%
                    </p>
                  </div>
                </div>

                <p className="pt-3 font-semibold">{item.amount}</p>
                <p className="font-extralight text-xs text-white/50">
                  {item.usdt}{" USDT"}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="w-5/12 flex-auto">
          <p>Recent Activities</p>

          <div className="bg-atlas_gray w-full h-full my-4 rounded-lg">
           
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        type={contentType}
      />
    </div>

    <div className="hidden lg:flex border-l border-atlas_gray flex-grow">
        <RightSidebar />
      </div>
    
    </div>
  );
};

export default WalletPage;
