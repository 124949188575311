import axios, { AxiosRequestConfig } from "axios";
import React, { useState, useRef, useEffect, useContext } from "react";
import IRate from "../data/rate";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const AppContext = React.createContext(
  {} as {
    rates: IRate[];
    sidebarOpen: boolean;
    updateSidebarOpen: (data: any) => void;
    topComponentRef: React.MutableRefObject<HTMLDivElement | null>;
    sendRequest: (method: string, url: string, data: any) => any;
    toggleDarkMode: () => void;
  }
);

export const useAppContext = () => useContext(AppContext);

interface Props {
  children: React.ReactNode;
}

const isDarkMode = () =>
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

export const AppProvider = ({ children }: Props) => {
  const [token, setToken] = useState(localStorage.getItem("token")?.trim());
  const [rates, setRates] = useState<IRate[]>([])
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(isDarkMode())

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
  }

  const topComponentRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function sendRequest(method: string, url: string, data: any) {
    var options: AxiosRequestConfig = {
      method: method,
      url: url,
    };

    if (data) {
      options.data = data;
    }

    // TODO: this is probably not the best way to do it
    // because it creates the instance everytime, but i guess
    // we need to because the token needs to be updated
    var customInstance = axios.create();
    // Add a request interceptor
    customInstance.interceptors.request.use(function (config) {
      // console.log("creating request: ");
      // Do something before request is sent
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["X-Refresh-Token"] = `Bearer ${localStorage.getItem("refresh_token")?.trim() || ""}`;
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["Access-Control-Allow-Headers"] = "Content-Type";
      config.baseURL = process.env.REACT_APP_BASE_URL;
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    customInstance.interceptors.response.use(function (res) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      // console.log("response headers: ", res.headers);
      let newRefreshToken = res.headers["x-refresh-token"];
      if (newRefreshToken) {
        localStorage.setItem("refresh_token", newRefreshToken);
      }
      let newToken = res.headers["x-access-token"];
      if (newToken) {
        localStorage.setItem("token", newToken);
        setToken(newToken);
      }
      return res;
    }, function (err) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (!err.response) {
        console.error("unexpected error: ", err)
        if (err.message === "Network Error") {
          toast.error("Network Error");
        }
        throw err;
      }
      switch (err.response.status) {
        case 401:
          localStorage.clear();
          window.location.href = "/login";
          return err;
        case 400:
          var errs = err.response.data.errors.split(",");
          if (errs.length > 1) {
            err.response.data.errors = errs
              .reduce((acc: any, error: any) => {
                const [key] = error.split(" ", 1);
                acc[key] = error;
                return acc;
              }, {});
          }
          break;
        case 403:
          var message = "You do not have permission to perform this action";
          console.log(err.response.data)
          if (err.response.data.errors) {
            message = err.response.data.errors;
          }
          toast.error(message);
          break;
        default:
          //toast.error("something went wrong");
          break;
      }

      return Promise.reject(err);
    });
    return customInstance(options)
  }

  const updateSidebarOpen = (newData: any) => {
    setSidebarOpen(newData);
  };

  useEffect(() => {
    sendRequest("GET", "/api/v1/rates", null).then(res => {
      setRates(res.data.data);
    }).catch((err) => {
      console.log({ err });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = {
    rates,
    sidebarOpen,
    updateSidebarOpen,
    topComponentRef,
    sendRequest,
    toggleDarkMode,
  };

  return (
    <AppContext.Provider value={contextValue}>
      <div className={`${darkMode && "dark"}`}>
        {children}
        <ToastContainer />
      </div>
    </AppContext.Provider>
  );
};

export default AppContext;
