const data = [
  {
    title: "Bitcoin",
    description: "BTC",
    amount: 0.00001345,
    status: "increase",
    percentage: 1.3,
    usdt: 0.00001345 * 50000,
  },
  {
    title: "Ethereum",
    description: "ETH",
    amount: 2.3,
    status: "increase",
    percentage: 1.9,
    usdt: 20 * 3000,
  },
  {
    title: "Litecoin",
    description: "LTC",
    amount: 12.01,
    status: "decrease",
    percentage: 4.5,
    usdt: 12.0 * 500,
  },
  {
    title: "Ripple",
    description: "XRP",
    amount: 100.000,
    status: "decrease",
    percentage: 1.6,
    usdt: 10.0 * 0.5,
  },
  {
    title: "Dogecoin",
    description: "DOGE",
    amount: 1000.89,
    status: "increase",
    percentage: 2.3,
    usdt: 1000 * 0.1,
  },
  {
    title: "Cardano",
    description: "ADA",
    amount: 56.1,
    status: "decrease",
    percentage: 1.1,
    usdt: 100 * 1.5,
  },
];

export default data;
