const Group20 = ({ className, fillColor }: { className: string, fillColor: string }) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      className={className}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.96586 11.791V15.2517C9.22993 15.2416 8.67835 14.9493 8.61066 14.9118C8.60634 14.9089 8.60346 14.9075 8.60346 14.9075L8.52425 14.8629L5.74329 13.2585L3.70977 12.0848L0.452119 10.2039C0.187128 10.0498 0.0229492 9.76611 0.0229492 9.4608V6.0332C0.0315902 6.54878 0.604776 6.92467 0.604776 6.92467L8.81948 11.6326C9.21553 11.8788 9.96586 11.791 9.96586 11.791Z"
        fill={fillColor}
      />
      <path
        d="M9.96586 11.791V12.213C9.89241 12.2202 9.81752 12.2245 9.74407 12.2245C9.35955 12.2245 8.97502 12.1251 8.62938 11.9278L8.6265 11.9249L8.34711 11.7651L5.64104 10.214L5.32852 10.0354L0.63214 7.34376L0.279299 7.14213C0.178487 7.08453 0.0920772 7.01396 0.0229492 6.93475V6.0332C0.0315902 6.54878 0.604776 6.92467 0.604776 6.92467L8.81948 11.6326C9.21553 11.8788 9.96586 11.791 9.96586 11.791Z"
        fill={fillColor}
      />
      <path
        d="M8.88291 11.6672C8.71153 11.5981 8.62224 11.5246 8.60352 11.5073L8.88291 11.6672Z"
        fill={fillColor}
      />
      <path
        d="M11.1167 11.6688L11.5905 11.4009C11.4393 11.5219 11.278 11.6083 11.1167 11.6688Z"
        fill={fillColor}
      />
      <path
        d="M19.9361 6.18018V9.30534C19.9361 9.69707 19.7273 10.0585 19.3874 10.2544L13.8139 13.4732L11.3541 14.8932C10.8688 15.174 10.3878 15.259 9.96582 15.2518V11.7911C9.96582 11.7911 18.025 6.29828 19.3571 5.52923C19.4709 5.46298 19.5357 5.43274 19.5415 5.44282C19.5688 5.49466 19.638 5.62284 19.71 5.75965C19.8194 5.96128 19.9361 6.18018 19.9361 6.18018Z"
        fill={fillColor}
      />
      <path
        d="M19.5127 7.1006L19.1598 7.30366L19.1339 7.3195L19.0778 7.35118L18.5636 7.64642L18.4527 7.70979L18.3937 7.74291L18.2713 7.81348L17.7845 8.09287L17.472 8.27145L16.016 9.10531C15.653 9.31413 15.2829 9.52583 14.9171 9.7361L12.9138 10.8868L12.6474 11.0395L11.8409 11.5046C11.779 11.5406 11.7271 11.5709 11.6868 11.5939C11.6436 11.6198 11.6134 11.6371 11.5946 11.6472L11.5831 11.6544L11.5774 11.6587L11.1036 11.9266C10.7579 12.1253 10.3719 12.2247 9.98742 12.2247H9.96582V11.7912C9.96582 11.7912 17.8176 6.43956 19.301 5.5625L19.5271 5.69788C20.0599 6.01759 20.0527 6.7924 19.5127 7.1006Z"
        fill={fillColor}
      />
      <path
        d="M9.96586 11.791V12.213C9.89241 12.2202 9.81752 12.2245 9.74407 12.2245C9.35955 12.2245 8.97502 12.1251 8.62938 11.9278L8.6265 11.9249L8.34711 11.7651L5.64104 10.214L5.32852 10.0354L0.63214 7.34376L0.279299 7.14213C0.178487 7.08453 0.0920772 7.01396 0.0229492 6.93475V6.0332C0.0315902 6.54878 0.604776 6.92467 0.604776 6.92467L8.81948 11.6326C9.21553 11.8788 9.96586 11.791 9.96586 11.791Z"
        fill={fillColor}
      />
      <path
        d="M9.96582 11.7898V12.2132C10.0393 12.2204 10.1142 12.2247 10.1876 12.2247C10.5736 12.2247 10.9581 12.1253 11.3052 11.9266L11.3081 11.9237L11.5889 11.7638L14.3022 10.2085L14.6161 10.0299L19.3255 7.33101L19.6797 7.12938C19.7806 7.07178 19.867 7.00121 19.9361 6.922V6.01758C19.9275 6.5346 19.3528 6.91192 19.3528 6.91192L11.1151 11.6328C10.7176 11.8791 9.96582 11.7912 9.96582 11.7912V11.7898Z"
        fill={fillColor}
      />
      <path
        d="M19.5401 5.4401C20.073 5.75838 20.0658 6.53462 19.5272 6.84282L19.1729 7.04588L19.147 7.06173L19.0908 7.09341L18.5767 7.38864L18.4658 7.45201L18.4067 7.48513L18.2843 7.5557L17.7975 7.8351L17.485 8.01368L16.029 8.84753C15.6661 9.05636 15.296 9.26806 14.9302 9.47833L12.9269 10.629L12.6605 10.7817L11.854 11.2469C11.792 11.2829 11.7402 11.3131 11.6999 11.3361C11.6567 11.3621 11.6264 11.3793 11.6077 11.3894L11.5962 11.3966L11.5904 11.4009L11.1166 11.6688C10.4268 12.0663 9.57706 12.0663 8.88722 11.6688L8.8829 11.6659L8.60351 11.5061L5.89744 9.95502L5.58492 9.77644L0.888542 7.08477L0.535701 6.88314C-0.149818 6.49142 -0.148377 5.50203 0.538582 5.11174L0.555864 5.10166L4.17356 3.04798L5.0319 2.56121L8.43069 0.631384L8.49838 0.592499C9.42873 0.0639577 10.5722 0.07692 11.491 0.627064L11.4968 0.629942L14.2086 2.25157L15.2124 2.85212L19.5401 5.43866V5.4401Z"
        fill={fillColor}
      />
      <path
        d="M16.4495 5.64902C16.811 5.86505 16.8052 6.38927 16.4409 6.5981L16.2018 6.73491L16.1845 6.74499L16.1471 6.76659L15.7985 6.96678L15.7236 7.00998L15.6833 7.03303L15.5998 7.08055L15.27 7.26921L15.0583 7.39019L14.0732 7.95473C13.8269 8.09587 13.5778 8.23988 13.3301 8.38102L11.9749 9.16015L11.7949 9.26384L11.249 9.57924C11.2073 9.60372 11.1727 9.62389 11.1454 9.63973C11.1166 9.65701 11.095 9.66853 11.0834 9.67573L11.0762 9.68005L11.0719 9.68293L10.7508 9.86439C10.2841 10.1323 9.70951 10.1337 9.24146 9.86439H9.23858L9.04992 9.75494L7.21946 8.70506L7.00776 8.58408L3.82931 6.76227L3.59025 6.62546C3.12651 6.36047 3.12795 5.69079 3.59169 5.42724L3.60321 5.42004L6.05149 4.03028L6.63188 3.70048L8.93182 2.39425L8.97791 2.36832C9.60726 2.01116 10.3821 2.0198 11.0028 2.39137L11.0071 2.39281L12.8419 3.49022L13.5216 3.89634L16.4495 5.64614V5.64902Z"
        fill={fillColor}
      />
      <path
        d="M16.7 6.26402C16.6669 6.13153 16.5833 6.00911 16.4508 5.9299L13.5215 4.1801L12.8418 3.77397L11.007 2.67657H11.0041C10.382 2.30213 9.60861 2.29348 8.97781 2.65208L8.93317 2.67801L6.63322 3.9828L6.05139 4.3126L3.60311 5.70236L3.59159 5.70956C3.40581 5.81469 3.29492 5.98607 3.25891 6.16897C3.2013 5.89246 3.3122 5.58858 3.59159 5.42872L3.60311 5.42297L6.05139 4.0332L6.63322 3.70341L8.93317 2.39718L8.97781 2.37125C9.60861 2.01409 10.382 2.02273 11.0041 2.3943H11.007L12.8418 3.49314L13.5215 3.90071L16.4508 5.65051C16.6755 5.78589 16.7576 6.0408 16.7 6.26402Z"
        fill={fillColor}
      />
      <path
        d="M8.74316 11.9917C8.74316 11.9917 9.77432 12.8025 11.1166 12.0335L8.74316 11.9917Z"
        fill={fillColor}
      />
      <path
        d="M19.4567 6.71888L19.0995 6.92338L19.0779 6.93635L19.0203 6.96803L18.5061 7.26327L18.3967 7.32663L18.3362 7.3612L18.2138 7.43176L17.727 7.71116L17.4145 7.88974L15.9585 8.72359C15.7612 8.83737 15.561 8.95114 15.3623 9.06635L14.8611 9.35439L12.8578 10.5051L12.5914 10.6577L11.7849 11.1229L11.7518 11.1416C11.7042 11.169 11.6639 11.192 11.6293 11.2122C11.589 11.2367 11.5602 11.2525 11.5429 11.2626L11.5386 11.2655L11.5343 11.2684L11.5228 11.2756L11.5156 11.2813L11.0475 11.5463C10.7307 11.7292 10.3692 11.8257 10.0034 11.8257C9.6376 11.8257 9.27756 11.7292 8.9564 11.5449L8.67701 11.385L5.97093 9.83396L5.65842 9.65538L0.962036 6.96371L0.609196 6.76209C0.361487 6.62095 0.204509 6.37756 0.175706 6.09961H0.0288086C0.0576119 6.41069 0.226111 6.7088 0.535747 6.88594L0.888588 7.08757L5.58497 9.77924L5.89749 9.95782L8.60356 11.5089L8.88295 11.6687L8.88727 11.6716C9.23291 11.8704 9.61744 11.9683 10.002 11.9683C10.3865 11.9683 10.7724 11.8689 11.1181 11.6702L11.5919 11.4023L11.5977 11.398L11.6092 11.3908C11.6279 11.3807 11.6582 11.3634 11.7014 11.3375C11.7417 11.3145 11.7935 11.2842 11.8555 11.2482L12.6619 10.783L12.9284 10.6304L14.9317 9.47968C15.2975 9.26942 15.6676 9.05771 16.0305 8.84889L17.4865 8.01503L17.799 7.83645L18.2858 7.55706L18.4082 7.48649L18.4673 7.45336L18.5782 7.39L19.0923 7.09476L19.1485 7.06308L19.1744 7.04724L19.5287 6.84418C19.6741 6.76065 19.7793 6.64255 19.8484 6.51006C19.7908 6.51294 19.7346 6.51438 19.677 6.51726C19.6208 6.59935 19.5474 6.66847 19.4581 6.72032L19.4567 6.71888Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default Group20;
