import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainContent from "../../Components/KYC/MainContent";
import StepButton from "../../Components/KYC/StepButton";
import VerificationStages from "../../Components/KYC/StepsArray";
import EmailId from "../../Components/KYC/EmailId";
import SideImage from "../../Components/LoginImage";

interface IUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  // when empty no verification has been done
  // possible stages are: email, personal_info, id_verification
  verification_stage: string;
}

const Index = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<string>(VerificationStages[0].id);
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [user, setUser] = useState<IUser>(JSON.parse(localStorage.getItem("user") || "{}"));

  useEffect(() => {
    // console.log(user);
    if (user && user.email) {
      setUser(user);
      // check which stage the user is in
      // use the stage to determine which step to show
      const index = VerificationStages.findIndex((step) =>
        step.id === user.verification_stage
      );

      if (index + 1 > VerificationStages.length) {
        navigate("/dashboard");
        return;
      }

      const completedSteps = VerificationStages.slice(0, index + 1).map((step) => step.id);
      console.log({ completedSteps, index });
      setCompletedSteps(completedSteps);
      setProgress((index + 1) * 25);
      setActiveStep(VerificationStages[index + 1].id);
    } else {
      toast.error("User not found, please login again");
    }
  }, [user]);

  return (
    <div className="lg:flex">
      <div className="bg-white dark:bg-atlas_black flex flex-col flex-1 min-h-screen lg:h-auto py-5 lg:py-10 gap-y-5">
        <EmailId onComplete={function (): void {
          throw new Error("Function not implemented.");
        }} />
        {/* <div className="flex-none w-1/7 min-h-screen lg:h-auto">
          <div className="font-sans text-white pt-10 pl-7">
            <h3>Verify Your Account </h3>
            <div className="pt-20">
              {VerificationStages.map((text, index) => (
                <StepButton
                  key={index}
                  completed={completedSteps.includes(text.id)}
                  isActive={text.id === activeStep}
                  label={text.label}
                  underText={text.underText}
                />
              ))}
            </div>
          </div>
        </div> */}
        {/* <div className="flex-auto bg-atlas_black">
          <div className="w-full bg-transparent h-1">
            <div
              className="bg-atlas_gold h-1.5 rounded-md"
              style={{ width: `${progress}%`, transition: `width 0.5s ease-in-out` }}
            ></div>
          </div>
          <MainContent
            activeStep={activeStep}
            onComplete={() => {
              // TODO: this should take a user or a stage, and use that to update the user object 
              // setUser(prev => {
              //   const u = {...prev, verification_stage: step};
              //   localStorage.setItem("user", JSON.stringify(u));
              //   return u;
              // })
              // 
              setUser(JSON.parse(localStorage.getItem("user") || "{}"));
            }}
          />
        </div> */}
      </div>
      <div className="hidden lg:block bg-white dark:bg-atlas_dark flex-1">
        <div className="flex justify-center items-start h-full">
          <SideImage />
          {/* <img src={LoginImage} alt="patterns" className=" h-full " /> */}
        </div>
      </div>
    </div>
  );
};

export default Index; 
