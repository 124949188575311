import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import SplashScreen from "./splash";

const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(token ? true : false);
    setLoading(false);
  }, []);

  if (loading) {
    return <div>{<SplashScreen />}</div>;
  }

  // render the dashboard outlet if authenticated
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
