import React from 'react'
import TableComponent from '../TableComponent'
import Ellipse from '../../assets/Ellipse.svg'

const AllTransactions = () => {
    const columns = [
        { field: "crypto", headerName: "Crypto" },
        { field: "asset", headerName: "Assets" },
        { field: "price", headerName: "Price" },
        { field: "change", headerName: "Change" },
        { field: "Accout", headerName: "Account" },
        // Add more columns as needed
      ];
    
      const dataTable = [
        {
          id: 1,
          crypto: [
            {
              imageSrc: Ellipse,
              title: "USDT",
              description: "TetherUS",
            },
          ],
          amount: [{ amount: 50.0, currency: "$0.00" }],
          price: 1.0,
          change: [{ percentage: 4.0, status: "increase" }],
          action: () => console.log("Action for row 1"),
        },
        {
          id: 2,
          crypto: [
            {
              imageSrc: Ellipse,
              title: "USDT",
              description: "TetherUS",
            },
          ],
          amount: [{ amount: 50.0, currency: "$0.00" }],
          price: 1.0,
          change: [{ percentage: 4.0, status: "decrease" }],
          action: () => console.log("Action for row 1"),
        },
        {
          id: 3,
          crypto: [
            {
              imageSrc: Ellipse,
              title: "USDT",
              description: "TetherUS",
            },
          ],
          amount: [{ amount: 50.0, currency: "$0.00" }],
          price: 1.0,
          change: [{ percentage: 4.0, status: "increase" }],
          action: () => console.log("Action for row 1"),
        },
        {
          id: 4,
          crypto: [
            {
              imageSrc: Ellipse,
              title: "USDT",
              description: "TetherUS",
            },
          ],
          amount: [{ amount: 50.0, currency: "$0.00" }],
          price: 1.0,
          change: [{ percentage: 4.0, status: "increase" }],
          action: () => console.log("Action for row 1"),
        },
        {
          id: 5,
          crypto: [
            {
              imageSrc: Ellipse,
              title: "USDT",
              description: "TetherUS",
            },
          ],
          amount: [{ amount: 50.0, currency: "$0.00" }],
          price: 1.0,
          change: [{ percentage: 4.0, status: "increase" }],
          action: () => console.log("Action for row 1"),
        },
        // Add more rows as needed
      ];
  return (
    <div className='font-sans'>
        <p className="text-white py-4 font-sans font-extralight text-xs">
        Home {">"}{" "}
      </p>

      <div className="my-5">
        <div className="flex justify-between my-4 text-white">
          <p className='text-sm font-semibold'>All Crypto</p>
        </div>
        <TableComponent columns={columns} data={dataTable}/>
      </div>
    </div>
  )
}

export default AllTransactions