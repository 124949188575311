import EmailId from "./EmailId";
import IdVerification from "./IdVerification";
import PersonalInfo from "./PersonalInfo";

interface props {
  onComplete: () => void
}

const StepOneContent = (props : props) => <EmailId {...props}/>;
const StepTwoContent = (props : props) => <PersonalInfo {...props}/>;
const StepThreeContent = (props: props) => <IdVerification {...props}/>;

const VerificationStages = [
  {
    id: "email",
    label: "Email Verification",
    underText: "",
    component: StepOneContent,
  },
  {
    id: "personal_info",
    label: "Personal Information",
    underText: "Submit information like your name, email, and address",
    component: StepTwoContent,
  },
  {
    id: "id_verification",
    label: "Identity Verification",
    underText: "Submit a valid Identification card & complete the facial recognition test",
    component: StepThreeContent,
  },
];

export default VerificationStages;
