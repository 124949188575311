import React, { useContext } from "react";
import logo from "../../assets/Logo.svg";
import { IoIosNotificationsOutline } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import myContext from "../../Context/AppContext";
import { MdOutlineClose } from "react-icons/md";
import { useDashboardContext } from "../../Context/DashboardContext";

const Header = () => {
  const { user } = useDashboardContext();
  const { sidebarOpen, updateSidebarOpen, topComponentRef } =
    useContext(myContext);
  return (
    <div
      ref={topComponentRef}
      className="flex justify-between items-center py-6 px-14 border-b border-atlas_gray sticky top-0 bg-atlas_dark z-10"
    >
      <div>
        <img className="w-8 h-8" src={logo} alt="logo" />
      </div>
      <div className="hidden lg:flex items-center">
        <IoIosNotificationsOutline className="text-white w-6 h-6 mr-6" />

        <div className="flex items-center">
          <p className="text-white font-sans px-3 text-center font-normal text-sm">
            {user}
          </p>
          <img
            className="w-8 h-8 rounded-full"
            src="https://avatar.iran.liara.run/public/9"
            alt="avatar"
          />
        </div>
      </div>
      <div className="lg:hidden relative ">
        <div
          className={`absolute inset-0 flex items-center justify-end transition-opacity duration-300 ${
            sidebarOpen ? "opacity-0" : "opacity-100"
          }`}
        >
          <button
            className=" text-white p-2"
            onClick={() => updateSidebarOpen(!sidebarOpen)}
          >
            {<RxHamburgerMenu size={30} />}
          </button>
        </div>

        <div
          className={`absolute inset-0 flex items-center justify-end transition-opacity duration-300 ${
            sidebarOpen ? "opacity-100" : "opacity-0"
          }`}
        >
          <button
            className="text-white p-2"
            onClick={() => updateSidebarOpen(!sidebarOpen)}
          >
            <MdOutlineClose size={30} />
          </button>
        </div>
      </div>
      {/* Your page content here */}
    </div>
  );
};

export default Header;
