import React, { ReactNode } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const carousel = (props: { deviceType?: string, children?: ReactNode }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4, // Show 4 cards at a time
      slidesToSlide: 1, // Slide one card at a time
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3, // Show 3 cards at a time
      slidesToSlide: 1, // Slide one card at a time
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // Show 1 card at a time
      slidesToSlide: 1, // Slide one card at a time
    }
  };

  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={props?.deviceType !== "mobile" ? true : false}
      autoPlaySpeed={2500}
      keyBoardControl={true}
      customTransition="transform 0.5s ease-in-out"
      transitionDuration={500}
      containerClass="carousel-container mx-auto px-4"
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
      deviceType={props?.deviceType ?? "desktop"}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-5"
    >
      {props.children}
    </Carousel>
  );
};

export default carousel;
