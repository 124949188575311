import { useState } from "react";
import FileUpload from "./FileUpload";
import emoji from "../../assets/icon _Happy and Smile_.svg";
import FaceAuth from "./FaceAuth";

interface Props {
  onComplete?: () => void;
}

const IdVerification = ({ onComplete }: Props) => {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
    onComplete && onComplete();
  };

  const renderModalContent = () => {
    switch (currentStep) {
      case 1:
        return <FileUpload onNext={nextStep} />;
      case 2:
        return <FaceAuth onNext={nextStep} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="flex justify-end pt-6 pr-9">
        <p className="flex items-center font-sans text-white text-sm">
          {currentStep === 1 ?
            <>
              <p>We really need this part &nbsp;</p><img src={emoji} alt="emoji" />
            </>
            :
            <>
              <p>This is the last step... I promise &nbsp;</p><img src={emoji} alt="emoji" />
            </>
          }
        </p>
      </div>

      <div className="pt-10 lg:px-20 px-10">
        <h3 className="text-4xl font-sans text-white font-semibold pt-8 pb-2">
          Identity Verification
        </h3>

        <p className="py-4 font-sans text-white">
          Provide a Goverment Issued ID card
        </p>

        <div className="py-7 flex font-sans text-white gap-11">
          <div>
            <p className="font-semibold">Image requirements</p>

            <div className="bg-atlas_gray py-5 px-7 rounded-xl mt-8">
              <ul className="text-gray-300 text-sm list-disc">
                <li>File size should not be over 3mb</li>
                <li>Clear & not blurry</li>
                <li>JPG, JPEG, PNG</li>
              </ul>
            </div>
          </div>

          <div className="bg-atlas_gray w-6/12 rounded-xl">
            {renderModalContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdVerification;
