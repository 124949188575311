import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import placeholder from "../../assets/placeholder.svg";
import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/arrow-right.svg";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import TableComponent from "../TableComponent";
import Ellipse from "../../assets/Ellipse.svg";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import Modal from "../Modals/Modal";
import AllTransactions from "./AllTransactions";
import RightSidebar from "./RightSidebar";

// Register necessary components and plugins

const DashboardPage = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isChecked, setIsChecked] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [currentView, setCurrentView] = useState("default");

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleTitle = (title: string) => {
    setContentType(title);
  };

  const columns = [
    { field: "crypto", headerName: "Crypto" },
    { field: "asset", headerName: "Assets" },
    { field: "price", headerName: "Price" },
    { field: "change", headerName: "Change" },
    { field: "Accout", headerName: "Account" },
    // Add more columns as needed
  ];

  const dataTable = [
    {
      id: 1,
      crypto: [
        {
          imageSrc: Ellipse,
          title: "USDT",
          description: "TetherUS",
        },
      ],
      amount: [{ amount: 50.0, currency: "$0.00" }],
      price: 1.0,
      change: [{ percentage: 4.0, status: "increase" }],
      action: () => console.log("Action for row 1"),
    },
    {
      id: 2,
      crypto: [
        {
          imageSrc: Ellipse,
          title: "USDT",
          description: "TetherUS",
        },
      ],
      amount: [{ amount: 50.0, currency: "$0.00" }],
      price: 1.0,
      change: [{ percentage: 4.0, status: "decrease" }],
      action: () => console.log("Action for row 1"),
    },
    {
      id: 3,
      crypto: [
        {
          imageSrc: Ellipse,
          title: "USDT",
          description: "TetherUS",
        },
      ],
      amount: [{ amount: 50.0, currency: "$0.00" }],
      price: 1.0,
      change: [{ percentage: 4.0, status: "increase" }],
      action: () => console.log("Action for row 1"),
    },
    // Add more rows as needed
  ];

  const data = {
    labels: ["BTC", "ETH", "SOL", "Others"],
    datasets: [
      {
        data: [300, 50, 100, 40],
        backgroundColor: [
          "rgb(213,176,113)",
          "rgb(235,87,87)",
          "rgb(189,189,189)",
          "rgb(47,128,237)",
        ],
        borderColor: "transparent",
        hoverOffset: 4,
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    cutout: "80%",
    plugins: {
      legend: {
        display: true,
        position: "left",
        onClick: () => {},
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 6,
          font: {
            size: 12,
            weight: "normal",
          },
          color: "white",
          pointStyle: "rectRounded",
        },
      },
    },
  };

  const handleSeeAllClick = () => {
    setCurrentView("allTransactions");
  };

  return (
    <div className="flex">
      <div className="mx-6 lg:w-4/5 w-full">
        {currentView === "default" && (
          <>
            <p className="text-white py-4 font-sans font-extralight text-sm">
              Home {">"}{" "}
            </p>

            <div className="flex justify-between gap-3">
              <div className="w-3/6 px-6 py-4 bg-atlas_gray border-2 border-solid border-white/10 rounded-lg flex justify-between flex-auto">
                <div>
                  <p className="text-white  pb-2 font-sans">Net Assets </p>
                  <p className="text-white  py-4 font-sans text-5xl">50.00</p>

                  <div className="flex  text-white font-sans">
                    <div className="flex items-start pr-3">
                      <div className="bg-[#816F4F] w-4 h-4 rounded-full flex-shrink-0  ">
                        <img
                          src={arrowLeft}
                          alt="arrow-up"
                          className="w-4 h-4"
                        />
                      </div>

                      <div className="flex flex-col justify-center text-left pl-1">
                        <span className="text-xs">Deposit</span>
                        <span className="text-base font-semibold">
                          50.00
                          <span className="text-xs">USDT</span>
                        </span>
                      </div>
                    </div>

                    <div className="flex items-start">
                      <div className="bg-[#8C4242] w-4 h-4 flex-shrink-0 rounded-full">
                        <img
                          src={arrowRight}
                          alt="arrow-up"
                          className="w-4 h-4 "
                        />
                      </div>
                      <div className="flex flex-col justify-center text-left pl-1">
                        <span className="text-xs">Withdraw</span>
                        <span className="text-base font-semibold">
                          0.00
                          <span className="text-xs">USDT</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <img src={placeholder} alt="placeholder" />
                </div>
              </div>

              <div className="w-1/6 flex-auto px-6 pt-4 bg-atlas_gray rounded-lg border-2 border-solid border-white/10">
                <h1 className="text-white font-sans">Current Assets</h1>

                <div className="">
                  <div className="w-full">
                    <Doughnut data={data} options={options} />
                  </div>
                </div>
              </div>
            </div>

            <div className="my-4">
              <button
                onClick={() => {
                  handleTitle("Trade");
                  handleOpenModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_gold border-white/10 rounded-lg"
              >
                Trade
              </button>
              <button
                onClick={() => {
                  handleTitle("Buy");
                  handleOpenModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm mx-4 hover:bg-atlas_gold border-white/10 rounded-lg"
              >
                Withdraw
              </button>
              <button
                onClick={() => {
                  handleTitle("Convert");
                  handleOpenModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_gold border-white/10 rounded-lg"
              >
                Convert
              </button>
            </div>

            <div className="flex justify-between mt-24 mb-4 text-white">
              <p>Assets</p>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="hidden"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  aria-hidden="true"
                />
                {isChecked ? (
                  <MdCheckBox className="text-atlas_gold w-5 h-5 mt-1" />
                ) : (
                  <MdCheckBoxOutlineBlank className="text-transparent w-5 h-5 mt-1 rounded-sm border-2 border-solid border-atlas_gray" />
                )}
                <span className="ml-2 text-xs">My Assets</span>
              </label>
            </div>

            <TableComponent columns={columns} data={dataTable} />

            <div className="my-10 underline pb-2 text-white flex justify-center items-center">
              <button
                className="text-sm font-sans text-white"
                onClick={handleSeeAllClick}
              >
                See All
              </button>
            </div>

            <div className="my-5">
              <div className="flex justify-between my-4 text-white">
                <p>Recent Transaction</p>
              </div>
              <TableComponent columns={columns} />
            </div>

            <Modal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              type={contentType}
            />
          </>
        )}
        {currentView === "allTransactions" && <AllTransactions />}
      </div>

      <div className="hidden lg:flex border-l border-atlas_gray flex-grow">
        <RightSidebar />
      </div>
    </div>
  );
};

export default DashboardPage;
