const data = [
    {
        index: 1,
        date : 8,
        amount: 12000,
        crypto: "BTC",
        cryptoAmount: 0.01345,
    },
    {
        index: 2,
        date : 19,
        amount: 200000,
        crypto: "ETH",
        cryptoAmount: 2.3,
    },
    {
        index: 3,
        date : 29,
        amount: 3000,
        crypto: "LTC",
        cryptoAmount: 12.01,
    },
    // {
    //     date : 11,
    //     amount: 40000,
    //     crypto: "XRP",
    //     cryptoAmount: 100.000,
    // },
    // {
    //     date : 12,
    //     amount: 50000,
    //     crypto: "DOGE",
    //     cryptoAmount: 1000.89,
    // },
    // {
    //     date : 13,
    //     amount: 60000,
    //     crypto: "ADA",
    //     cryptoAmount: 56.1,
    // },
]

export default data;