import React from "react";
interface CalenderIconProps {
  number: number;
  width?: string;
  height?: string;
}

const CalenderIcon = ({ number, width , height }:CalenderIconProps) => {
  return (
    <div className="relative inline-flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : "20"}
        height={height ? height : "20"}
        fill="white"
        className="bi bi-calendar2-fill"
        viewBox="0 0 16 16"
      >
        <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM2.545 3h10.91c.3 0 .545.224.545.5v1c0 .276-.244.5-.546.5H2.545C2.245 5 2 4.776 2 4.5v-1c0-.276.244-.5.545-.5" />
      </svg>
      {number && (
        <span className="absolute top-0.5 text-black font-sans font-semibold text-xs rounded-full px-2 py-1">
          {number}
        </span>
      )}
    </div>
  );
};

export default CalenderIcon;
