import { useMediaQuery } from 'react-responsive';
import Carousel from './carousel';
import CarouselCard from './CarouselCards';
import Section2_1Img from "../../assets/section2.png";
import Section2_2Img from "../../assets/section2.2.png";
import Section2_3Img from "../../assets/section2.3.png";
import Section2_4Img from "../../assets/section2.4.png";
import Section2_5Img from "../../assets/section2.5.png";

const ResponsiveCarousel = () => {
  // Use media query to detect screen size
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const cards = [
    {
      imageSrc: Section2_1Img,
      title: "Tresury Management and Remittance",
      description:
        "Enjoy seamless currency exchange and remittance services at competitive rates. Our platform ensures fast and secure transfers to your desired location, so you can focus on what matters.",
    },
    {
      imageSrc: Section2_2Img,
      title: "Lending Services",
      description:
        "Whether for business or personal needs, our flexible, transparent lending solutions come with straightforward eligibility—and support every step of the way.",
    },
    {
      imageSrc: Section2_3Img,
      title: "Payment Solutions",
      description:
        "We provide secure, fast payment options for individuals and businesses—ensuring confident, dependable transactions with Atlax Exchange.",
    },
    {
      imageSrc: Section2_4Img,
      title: "OTC",
      description:
        "Our over the counter service is available for large volumes and personalised transactions to suit your specified needs.",
    },
    {
      imageSrc: Section2_5Img,
      title: "Investment Opportunities",
      description:
        "Grow your wealth with our diverse investment options. We’re dedicated to providing you with secure, informed choices to help you meet your financial goals.",
    },
  ];

  return (
    <div className="container mx-auto">
      {isDesktop ? (
        <Carousel>
          {cards.map((card, index) => (
            <CarouselCard
              key={index}
              imageSrc={card.imageSrc}
              title={card.title}
              description={card.description}
            />
          ))}
        </Carousel>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {cards.map((card, index) => (
            <CarouselCard
              key={index}
              imageSrc={card.imageSrc}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ResponsiveCarousel;
