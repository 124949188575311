import logimage from '../assets/LoginImage.png';

function SideImage() {
  return (
    <>
      <img className='h-full w-full'  alt='side' src={logimage} />
    </>
  )
}

export default SideImage;