import React from "react";
import imgbox from "../../assets/auth.svg";

interface FaceAuthProps {
  onNext: () => void;
}

const FaceAuth = ({ onNext}: FaceAuthProps) => {
  return (
    <div className="mx-5 my-7">
      <h4 className="text-center">Authenticate with face</h4>

      <div className="flex justify-center py-5">
        <img src={imgbox} alt="box" />
      </div>

      <div className="flex justify-end">
        <button
          onClick={onNext}
          className="py-2 px-7 rounded-md text-black font-semibold mt-4 bg-atlas_gold"
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default FaceAuth;
