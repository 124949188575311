import { useState } from "react";
import { Link } from "react-router-dom";
import LoginImage from "../../assets/LoginImage.png";
import OtpComponent from "../../Components/OtpComponent";
import ResendCounter from "../../Components/KYC/ResendCounter";
import { toast } from "react-toastify";

const OTP = () => {
  const [counter, setCounter] = useState<number>(() => {
    const storedCounter = localStorage.getItem("counter");
    const parsedCounter =
      storedCounter !== null ? parseInt(storedCounter, 10) : 90;
    return parsedCounter !== null && parsedCounter > 60 ? parsedCounter : 90;
  });

  function resendCode() {
   
        toast.success("Code sent successfully");
        setCounter(90);
  }
  return (
    <div>
      <div className="lg:flex">
        <div className="bg-atlas_black flex flex-col flex-1 min-h-screen lg:h-auto gap-y-20">
          <div className="flex flex-1 flex-col  items-center justify-center">
            <div className="font-manrope text-white lg:px-5 md:px-16 px-5 pb-10">
              <h3 className="text-4xl pb-2">OTP</h3>
              <p className="font-normal text-opacity-70 text-base text-atlas_grey ">
                Enter the 6 digit Verificatin code send to your mobile <br />{" "}
                number
              </p>
              <div className="lg:py-32">
                <OtpComponent />
                <div className="text-white text-sm pb-5 font-normal font-manrope flex justify-between my-5">
                  <p className="text-atlas_orange flex gap-1">
                      Your code expires in {' '} <ResendCounter counter={counter} setCounter={setCounter} />{" "}
                  </p>

                  <button className={`${
                      counter > 0 ? "cursor-not-allowed" : "cursor-pointer"
                    } flex gap-x-1 text-atlas_orange`}
                    onClick={resendCode}>
                    Resend Code
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden lg:block bg-atlas_dark flex-1">
          <div className="flex justify-center items-center h-full">
            <img
              className="h-screen w-full object-cover"
              alt="side"
              src={LoginImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTP;
