import {useState} from "react";
import globe from "../../assets/Globe.png";
import logo from "../../assets/Logo.svg";
import SuccessModal from "../Modals/Success";

const WaitingList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setIsModalVisible(true);
  }

  const handleCloseModal = () => {
    setIsModalVisible(false);
  }
  return (
    <div className="relative w-screen h-screen overflow-hidden bg-atlas_grey">
      {/* Top Bar with Logo and Title */}
      <div className="flex items-center py-4 mx-12">
        <img className="w-12 h-12 p-2 cursor-pointer" src={logo} alt="logo" />
        <h4 className="text-atlas_orange cursor-pointer font-nunito">
          Atlax Exchange™
        </h4>
      </div>

      {/* Globe Image positioned in the bottom right */}
      <div className="absolute bottom-0 right-20 transform translate-x-1/2 translate-y-1/2">
        <img src={globe} alt="Globe" className="" />
      </div>

      {/* Centered Form */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="font-manrope rounded-lg max-w-xl w-full lg:mx-4 mx-12">
        <h2 className="text-3xl font-bold mb-10 text-left">Join our Waitlist</h2>
          <form className="">
            <h2 className="text-2xl font-bold mb-1 text-left">
              Create Account
            </h2>
            <p className="text-base font-light text-atlas_offgrey">
              Enter the following information correctly to join our waitlist
            </p>

            <div className="my-6">
              <label className="block text-atlas_black font-bold">
                Legal First Name
              </label>
              <input
                type="text"
                className="px-3 py-3 w-full text-black bg-atlas_silver focus:ring-0 rounded-md focus:outline-none border-atlas_slate border-solid border"
                placeholder="Enter your First name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-atlas_black font-bold">
                Legal Late Name
              </label>
              <input
                type="text"
                className="px-3 py-3 w-full text-black bg-atlas_silver focus:ring-0 rounded-md focus:outline-none border-atlas_slate border-solid border"
                placeholder="Enter your Last name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-atlas_black font-bold">Email</label>
              <input
                type="email"
                className="px-3 py-3 w-full text-black bg-atlas_silver focus:ring-0 rounded-md focus:outline-none border-atlas_slate border-solid border"
                placeholder="Enter your email"
              />
            </div>
            <button
              onClick={handleOpenModal}
              type="submit"
              className={`bg-atlas_lightorange mb-4 w-full py-3 rounded-md text-black font-semibold mt-4`}
            >
              Submit
            </button>
            <SuccessModal isVisible={isModalVisible} onClose={handleCloseModal} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default WaitingList;
