import React, { createContext, useState , useContext } from 'react';

const DashboardContext = createContext(
    {} as {
        user: any,
        loading : boolean,
        login: any,
        logout: any
    }
);

export const useDashboardContext = () => useContext(DashboardContext);

interface Props {
  children: React.ReactNode;
}

export const DashboardProvider = ({ children }: Props) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchUserData = async () => {
        try {
          // const response = await fetch('/api/validate-token', { headers: { Authorization: `Bearer ${token}` } });
          // const userData = await response.json();
        const userData : any =  JSON.parse(localStorage.getItem("user") || "{}");
         setUser(userData);
        } catch (error) {
          console.error('Token validation failed:', error);
          localStorage.removeItem('token');
        } finally {
          setLoading(false);
        }
      };

      fetchUserData();
    } else {
      setLoading(false);
    }
  }, []);

  const login = (userData: React.SetStateAction<any>) => {
    localStorage.setItem('token', userData.access_token);
    localStorage.setItem('Refresh-token', userData.refresh_token);
    setUser(userData);
  };

const logout = () => {
    localStorage.removeItem('token');
    setUser(null as any | null);
};

const contextValue ={
    user,
    loading,
    login,
    logout
}

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContext;
