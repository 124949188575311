import React from "react";
import boxicon from "../../assets/boxicon.svg";
import Ellipse from "../../assets/Ellipse.svg";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { Link } from "react-router-dom";

const RightSidebar = () => {
  const data = [
    {
      title: "Bitcoin",
      description: "BTC",
      amount: 100,
      status: "increase",
      percentage: 1.3,
    },
    {
      title: "Ethereum",
      description: "ETH",
      amount: 20,
      status: "decrease",
      percentage: 1.9,
    },
    {
      title: "Litecoin",
      description: "LTC",
      amount: 12.0,
      status: "increase",
      percentage: 4.5,
    },
    {
      title: "Ripple",
      description: "XRP",
      amount: 10.0,
      status: "decrease",
      percentage: 1.6,
    },
  ];
  return (
    <div className="font-sans w-full">
      <p className="text-white p-3">Complete Your Registration</p>

      <div>
        {" "}
        <Link to="/kyc">
        <div className="flex justify-between mx-3 my-4 items-center">
          <div className="bg-atlas_gold rounded-full w-7 h-7 flex justify-center items-center text-black ">
            <p>1</p>
          </div>

          {/* Connector Line */}
          <div
            style={{
              height: "2px",
              backgroundColor: "#D5B071", // Or any color you prefer
              flexGrow: 1, // Allows the line to fill the space between
            }}
          ></div>

          <div className="w-3/4 h-3/4 bg-atlas_gray border border-solid border-atlas_gold rounded-lg p-2">
            <p className="text-sm text-white">Verify your Account</p>
            <div className="flex pt-3">
              <p className="text-xs text-white/50">
                Complete your verification process to start trading
              </p>
            </div>
            <div className="flex justify-end">
              <img src={boxicon} alt="" className="w-14 h-14" />
            </div>
          </div>
        </div>
        </Link>

        <div className="flex justify-between mx-3 my-4 items-center">
          <div className="bg-atlas_grey rounded-full w-7 h-7 flex justify-center items-center text-black ">
            <p>2</p>
          </div>

          {/* Connector Line */}
          <div
            style={{
              height: "2px",
              backgroundColor: "#2E2E2E", // Or any color you prefer
              flexGrow: 1, // Allows the line to fill the space between
            }}
          ></div>

          <div className="w-3/4 h-3/4 bg-atlas_gray border border-solid border-white/30 rounded-lg p-2">
            <p className="text-sm text-white">Make A Deposit</p>
          </div>
        </div>
        <div className="flex justify-between mx-3 my-4 items-center">
          <div className="bg-atlas_grey rounded-full w-7 h-7 flex justify-center items-center text-black ">
            <p>3</p>
          </div>

          {/* Connector Line */}
          <div
            style={{
              height: "2px",
              backgroundColor: "#2E2E2E", // Or any color you prefer
              flexGrow: 1, // Allows the line to fill the space between
            }}
          ></div>

          <div className="w-3/4 h-3/4 bg-atlas_gray border border-solid border-white/30 rounded-lg p-2">
            <p className="text-sm text-white">Start Trading</p>
          </div>
        </div>
      </div>

      <div className="my-24">
        <p className="pl-3 text-white">My Watchlist</p>

        {data.map((item, index) => (
          <div className="flex justify-between px-3 py-5" key={index}>
            <div className="flex">
              <img src={Ellipse} alt="" className="w-10 h-10 rounded-full" />
              <div className="flex flex-col pl-3 text-white font-semibold text-sm ">
                {item.title}
                <div className="text-white font-normal text-xs">
                  {item.description}
                </div>
              </div>
            </div>

            <div>
              <div className="flex flex-col text-white font-semibold text-sm ">
               <p className="text-center">${item.amount}</p>
                <div
                  className={` py-1 flex justify-center rounded-md item-center text-white font-semibold text-sm ${
                    item.status === "increase"
                      ? "bg-atlas_green/20"
                      : "bg-red-500/20"
                  }`}
                >
                  <div className="flex items-center">
                    {item.status === "increase" ? (
                      <MdArrowUpward className="text-atlas_green" />
                    ) : (
                      <MdArrowDownward className="text-red-500" />
                    )}
                  </div>
                  <p
                    className={`${
                      item.status === "increase"
                        ? "text-atlas_green"
                        : "text-red-500"
                    } font-normal text-xs`}
                  >
                    {item.percentage}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RightSidebar;
