
interface CardProps {
    imageSrc?: string;
    title: string;
    description: string;
  }
  
  function Card({ imageSrc, title, description }: CardProps) {
    return (
      <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white hover:scale-105 transition hover:duration-1000 ease-in-out">
        {imageSrc && (
          <img className="w-full h-3/6 object-cover" src={imageSrc} alt={title} />
        )}
        <div className="p-6 font-manrope">
          <h2 className="font-semibold text-xl mb-2">{title}</h2>
          <p className="text-gray-700 text-sm mb-4">{description}</p>
        </div>
      </div>
    );
  }
  
  export default Card;
  