import React, { useState, useEffect, useRef } from "react";
import fileupload from "../../assets/upload.svg";

interface FileUploadProps {
  onNext: () => void;
}

const FileUpload = ({ onNext }: FileUploadProps) => {
  const [frontPageImage, setFrontPageImage] = useState<string | null>(null);
  const [backPageImage, setBackPageImage] = useState<string | null>(null);
  const [activeButton, setActiveButton] = useState("button1");
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const button1Ref = useRef<HTMLButtonElement>(null);
  const button2Ref = useRef<HTMLButtonElement>(null);
 

  useEffect(() => {
    // Update the underline width based on the active button
    if (activeButton === "button1" && button1Ref.current) {
      setUnderlineWidth(button1Ref.current.offsetWidth);
    } else if (activeButton === "button2" && button2Ref.current) {
      setUnderlineWidth(button2Ref.current.offsetWidth);
    }
  }, [activeButton]);


  function shortenString(str: string, length: number) {
    if (str.length <= length) {
      return str;
    } else {
      return str.slice(0, length) + "...";
    }
  }

  const handleFrontPageImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setFrontPageImage(event.target.files[0].name);
    }
  };

  const handleBackPageImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setBackPageImage(event.target.files[0].name);
    }
  };

  return (
    <div className="mx-5 my-7">
      <div className="flex justify-end mt-3">
        <form className="w-7/12">
          <select
            id="countries"
            className="bg-atlas_gray border border-gray-300/50 text-sm text-white rounded-lg focus:outline-none focus:border-atlas_gold  block w-full p-1.5 "
          >
            <option selected>Select an ID card</option>
            <option value="national id">National Identity Card</option>
            <option value="passport">International Passport</option>
            <option value="voters card">Voters Card</option>
            <option value="drivers license">Drivers License</option>
            <option value="nin">NIN</option>
          </select>
        </form>
      </div>

      <div className="flex flex-col mt-5">
        <div className="flex justify-center">
          <button
            ref={button1Ref}
            className={`px-4 py-2 text-white text-xs`}
            onClick={() => setActiveButton("button1")}
          >
            Upload Front Page
          </button>
          <button
            ref={button2Ref}
            className={`px-4 py-2 text-white text-xs`}
            onClick={() => setActiveButton("button2")}
          >
            Upload Back Page
          </button>
        </div>

        <div className="flex justify-center w-full">
          <div
            className={`h-0.5 px-4 ${
              activeButton === "button1" ? "bg-white" : "bg-gray-300/50"
            } transition-all`}
            style={{
              width: `${underlineWidth}px`,
              transition: "width 0.3s ease-in-out",
            }}
          ></div>
          <div
            className={`h-0.5 px-4 ${
              activeButton === "button2" ? "bg-white" : "bg-gray-300/50"
            } transition-all`}
            style={{
              width: `${underlineWidth}px`,
              transition: "width 0.3s ease-in-out",
            }}
          ></div>
        </div>
      </div>

      <div className="border-2 border-gray-300/10 border-dashed rounded-xl my-5 px-8 py-12">
        <div className="flex justify-center items-center">
          <img src={fileupload} alt="fileupload" className="w-15" />
        </div>

        <form>
          <div className="flex justify-center items-center mt-5">
            <label
              htmlFor="img"
              className="bg-atlas_gold py-1 px-14 rounded-md text-black text-sm font-bold text-center cursor-pointer font-sans"
              onClick={() => document.getElementById("getFile")?.click()}
            >
              Upload
              <input
                type="file"
                accept="image/*"
                data-max-size="1048"
                id="getFile"
                className="hidden"
                onChange={(event) => {
                  if (activeButton === "button1") {
                    handleFrontPageImageChange(event);
                  } else if (activeButton === "button2") {
                    handleBackPageImageChange(event);
                  }
                }}
              />
            </label>
          </div>


          <div className="flex flex-col items-center">
            {frontPageImage && (
              <div className="mt-2 text-sm text-white">{`Front Page ${shortenString(frontPageImage, 10)}`}</div>
            )}
            {backPageImage && (
              <div className="mt-2 text-sm text-white">{`Back Page ${shortenString(backPageImage, 10)}`}</div>
            )}
          </div>
        </form>
      </div>
      <div className="flex justify-end">
        <button
          onClick={onNext}
          className="py-2 px-7 rounded-md text-black font-semibold mt-4 bg-atlas_gold"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default FileUpload;
