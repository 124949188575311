import React from 'react'
import Ellipse from '../../assets/Ellipse.svg'

const Trade = () => {
  return (
    <div>
        <div className='p-3'>Trade</div>
        <div className=" py-10">

        <div className="border border-solid border-white/50 mx-10 mb-9 rounded-lg p-3">
          <div className="flex justify-between">
            <div>
              <div className="flex items-center">
                <img className="w-4 h-4" src={Ellipse} alt="Ellipse" />
                <p className='pl-3'>Fiat Trading</p>
              </div>
              <p className="text-sm font-normal text-white/50 pl-7">
               Trade digital assets directly via various payment methods
              </p>
            </div>
          </div>
        </div>

        <div className="border border-solid border-white/50 mx-10 mb-9 rounded-lg p-3">
          <div className="flex justify-between">
            <div className="">
              <div className="flex items-center">
                <img className="w-4 h-4" src={Ellipse} alt="Ellipse" />
                <p className='pl-3'>P2P Trading</p>
              </div>
              <p className="text-sm font-normal text-white/50 pl-7">
               Trade digital assets directly via various payment methods
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-4">
        <button className="w-4/5 bg-atlas_gold text-white p-3 rounded-lg">Trade</button>
      </div>
      </div>
    </div>
  )
}

export default Trade