import React from "react";
import { SiConvertio } from "react-icons/si";
import Ellipse from "../../assets/Ellipse.svg";

const Convert = () => {
  return (
    <div className=" font-sans">
      <div className="border-b border-atlas_gold">
        <button className={`flex-1 py-3 px-7 "} rounded-tl-lg bg-atlas_gold `}>
          Convert
        </button>
      </div>

      <div className="border-b border-l border-r border-atlas_gold py-10">
        <p className="mx-10 py">From</p>

        <div className="border border-solid border-white/50 mx-10 mb-9 rounded-lg p-3">
          <div className="flex justify-between">
            <div>
              <div className="flex items-center">
                <img className="w-4 h-4" src={Ellipse} alt="Ellipse" />
                <select className="bg-atlas_gray border-none outline-none focus:outline-none text-sm">
                  <option>USDT</option>
                  <option>ETH</option>
                  <option>BTC</option>
                  <option>BNB</option>
                </select>
              </div>
              <p className="text-sm font-normal text-white/50">
                Available :<span>0.1</span>
              </p>
            </div>
            <p className="text-lg">0.0562</p>
          </div>
        </div>

        <div className="flex justify-center">
          <SiConvertio className="w-6 h-6 text-atlas_gold" />
        </div>

        <p className="mx-10 py-2">To</p>

        <div className="border border-solid border-white/50 mx-10 mb-9 rounded-lg p-3">
          <div className="flex justify-between">
            <div className="">
              <div className="flex items-center">
                <img className="w-4 h-4" src={Ellipse} alt="Ellipse" />
                <select className="bg-atlas_gray border-none outline-none focus:outline-none text-sm">
                  <option>ETH</option>
                  <option>USDT</option>
                  <option>BTC</option>
                  <option>BNB</option>
                </select>
              </div>
              <p className="text-xs font-normal text-white/50">
                Available :<span>0.12</span>
              </p>
            </div>
            <p className="text-lg">0.12</p>
          </div>
        </div>
        <div className="flex justify-center py-4">
          <button className="w-4/5 bg-atlas_gold text-white p-3 rounded-lg">
            Convert
          </button>
        </div>
      </div>
    </div>
  );
};

export default Convert;
